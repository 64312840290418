import {Location, useLocation, useNavigate} from 'react-router-dom'
import {paths} from '../constants/paths'

export const useGoBack = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const goBack = (fallbackPath?: string) => {
    if (location.state?.backgroundLocation?.pathname) {
      navigate(`${location.state.backgroundLocation.pathname}${location.state.backgroundLocation.search}`)
      return
    }
    if (fallbackPath) {
      navigate(fallbackPath)
      return
    }
    navigate(paths.dashboard.path)
  }

  return goBack
}

export const generatePathFromLocation = (location: Location) => {
  return `${location.pathname}${location.search}${location.hash}`
}
